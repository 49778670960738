<!-- 应用下载 -->
<template>
  <div class="about" id="about" name="about">
    <div class="about-content">
      <el-row :gutter="20">
        <el-col :span="16">
          <el-image
            :src="require('../assets/imgs/download/PC.png')"
            style="width: 685px; padding: 100px 50px"
          />
        </el-col>
        <el-col :span="8">
          <div>
            <div class="download-word">
              <p>大屏使用更方便</p>
              <p>专为电脑场景优化</p>
            </div>
            <div>
              <el-button class="download-button" round @click="downloadPC"
                >电脑端点击下载</el-button
              >
            </div>
            <div>
              <el-popover placement="right" width="400" trigger="click">
                <el-image :src="require('@/assets/imgs/qrcode2.png')" />
                <el-button class="download-button" round slot="reference"
                  >APP点击扫码下载</el-button
                >
              </el-popover>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "Software",
  methods: {
    /**
     * Windows桌面端下载
     */
    downloadPC() {
      window.open(
        this.$target + "/Download/ToyModelSetup4.0.6.exe",
        "_blank"
      );
    },
    /**
     * APP端下载
     */
    downloadApp() {
      window.open("https://sj.qq.com/appdetail/com.bt.toysmodel", "_blank");
    },
  },
};
</script>
<style scoped>
.about {
  background: url("../assets/imgs/download/pc-bg.jpg");
}
.about .content {
  padding: 20px 0;
  width: 1225px;
  margin: 0 auto;
}
.about .about-content {
  width: 1225px;
  margin: 0 auto;
}
.download-word {
  padding-top: 120px;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
  line-height: 36px;
  text-align: center;
  margin-bottom: 36px;
  font-size: 24px;
  width: 250px;
}
.download-button {
  background: linear-gradient(90deg, #ff5e3d 0, #fa2800 100%);
  width: 250px;
  line-height: 30px;
  font-size: 18px;
  opacity: 9;
  color: #fff;
  margin-bottom: 20px;
}
.download-button:hover {
  color: burlywood;
}
</style>
